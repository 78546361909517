import classNames from 'classnames';
import React, { FunctionComponent, useRef, useState, useLayoutEffect } from 'react';
import { Column } from '../Column';
import { Row } from '../Row';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
  placeholder?: string;
  floatLabel?: boolean;
  type?: 'text' | 'number' | 'date' | 'password' | 'email' | 'tel' | 'time';
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  value?: any;
  error?: string;
  postfix?: string | IconDefinition;
  maxLength?: number;
  disabled?: boolean;
  id: string;
  helpText?: string;
  minDate?: string;
};

export const InputV2: FunctionComponent<Props> = ({
  value,
  error,
  placeholder,
  type = 'text',
  onChange,
  onFocus,
  postfix,
  maxLength,
  disabled,
  id,
  floatLabel = false,
  helpText,
  minDate
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState('');

  useLayoutEffect(() => {
    let initialValue = String(value);
    if (type === 'number') {
      initialValue = initialValue.replace(/[^\d]/g, '');
    }
    setInputValue(initialValue);
  }, [value, type]);

  const inputValuePreventChange = (e: any) => {
    e.target.blur();
    e.stopPropagation();
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = e.target.value;

    if (type === 'number') {
      newValue = newValue.replace(/[^\d]/g, '');
    }

    setInputValue(newValue);
    onChange({
      ...e,
      target: {
        ...e.target,
        value: newValue
      }
    });
  };

  return (
    <Column className="relative w-full h-full">
      <Row
        align="center"
        justify="space-between"
        gap={4}
        className={classNames({
          'w-full h-10 p-2 bg-clip-padding border border-solid border-[#0000005c] rounded transition ease-in-out m-0 focus:outline-none':
            true,
          'border-red-500': error,
          'cursor-not-allowed bg-gray-100': disabled,
          'bg-white focus-within:text-gray-700 focus-within:border-[#0072FB]': !disabled
        })}
      >
        <div
          className={classNames({
            'relative w-full': true,
            'w-[80%]': postfix,
            'cursor-not-allowed': disabled
          })}
        >
          <input
            value={inputValue}
            ref={inputRef}
            {...(type === 'date' && { min: minDate })}
            {...(type === 'number' && { inputMode: 'numeric', pattern: '[0-9]*' })}
            disabled={disabled}
            onChange={handleChange}
            onFocus={onFocus}
            onWheel={inputValuePreventChange}
            placeholder={floatLabel ? ' ' : placeholder}
            type={type}
            id={id}
            className={classNames({
              'w-full text-base px-2.5 text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#0072FB] peer':
                true,
              'pb-1 pt-3': floatLabel && value !== '' && value !== undefined,
              'cursor-not-allowed': disabled
            })}
          />
          {floatLabel && (
            <label
              htmlFor={id}
              onClick={() => {
                inputRef.current?.focus();
              }}
              className={classNames({
                'absolute text-base text-gray-400 duration-300 transform scale-75 -top-1 origin-top-left px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 left-1':
                  true,
                'cursor-not-allowed': disabled,
                'cursor-text': !disabled
              })}
            >
              {placeholder}
            </label>
          )}
        </div>
        {postfix && (
          <span
            className={classNames({
              'text-base text-gray-400 transition-all duration-300 pr-2.5': true,
              'cursor-not-allowed bg-gray-100': disabled
            })}
          >
            {typeof postfix === 'string' ? (
              postfix
            ) : (
              <FontAwesomeIcon icon={postfix} fontSizeAdjust={16} className="text-current" />
            )}
          </span>
        )}
      </Row>
      <Row align="center" justify="space-between" className="absolute -bottom-4 left-0 w-full text-sm px-2">
        <span
          className={classNames({
            'text-xs': true,
            'text-red-500 block': error,
            'text-gray-400 block': helpText,
            hidden: !(error || helpText)
          })}
        >
          {error ? error : helpText}
        </span>
        {maxLength && (
          <span className="text-sm text-gray-400">
            {inputValue.length || 0}/{maxLength}
          </span>
        )}
      </Row>
    </Column>
  );
};
