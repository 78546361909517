import { Column } from '@/components';
import { Service } from '@/containers/Agenda/components/CreateEventModal/SelectService/Service';
import { ServiceI } from '@/types/cyclone/models';
import { GetServicesI } from '@/types/cyclone/requests';
import React, { Dispatch, SetStateAction } from 'react';

interface ListServicePropsI {
  nextStep: () => void;
  setItemSelected: Dispatch<SetStateAction<ServiceI | undefined>>;
  services: GetServicesI;
}

export const ListServices: React.FC<ListServicePropsI> = ({ services, setItemSelected, nextStep }) => {
  const sortedServices = [...services].sort((a, b) => a.name.localeCompare(b.name));

  return (
    <Column className="min-h-96">
      {sortedServices.map((service) => {
        return (
          <Service
            key={service.id}
            service={service}
            selectService={() => {
              setItemSelected(service);
              nextStep();
            }}
          />
        );
      })}
    </Column>
  );
};
