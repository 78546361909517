import React, { FunctionComponent } from 'react';
import { useAuth } from '@/contexts';
import { Column } from '../Column';
import classNames from 'classnames';
import { Row } from '../Row';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faChevronCircleRight, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { useWindowResize } from '@/hooks';
import { SupportedCountriesE } from '@/types/cyclone/models';
import { Spinner } from '../Spinner';

type PricingPlansProps = {
  onSubmit: (planId: number, staffCount?: number) => void;
  country?: SupportedCountriesE;
  isLoading: boolean;
};

export const PricingPlans: FunctionComponent<PricingPlansProps> = ({
  onSubmit,
  country = SupportedCountriesE.ARGENTINA,
  isLoading
}) => {
  const { session } = useAuth();
  const [loading, setLoading] = React.useState<boolean>(false);
  const { isMobileSize } = useWindowResize();
  const [numberOfProfessionals, setNumberOfProfessionals] = React.useState<number>(2);

  //for internal use
  const [planSelected, setPlanSelected] = React.useState<number | undefined>(undefined);

  const isReferred = session?.is_invited || false;

  const getPricesByCountry = (country: string) => {
    switch (country) {
      case 'Chile':
        return {
          professionalPrice: 9900,
          businessBasePrice: 19900,
          businessStaffPrice: 3000,
          referralDiscount: 2000
        };
      case 'Uruguay':
        return {
          professionalPrice: 449,
          businessBasePrice: 749,
          businessStaffPrice: 150,
          referralDiscount: 100
        };
      default:
        return {
          professionalPrice: 6900,
          businessBasePrice: 10900,
          businessStaffPrice: 3000,
          referralDiscount: 1000
        };
    }
  };

  const { professionalPrice, businessBasePrice, businessStaffPrice, referralDiscount } =
    getPricesByCountry(country);

  const calculateAdditionalPrice = (count: number): number => {
    if (count <= 2) {
      return 0;
    } else {
      return businessStaffPrice + calculateAdditionalPrice(count - 1);
    }
  };

  const professionalAdditionalPrice =
    numberOfProfessionals > 1 ? calculateAdditionalPrice(numberOfProfessionals) : 0;
  const businessPlan = professionalAdditionalPrice
    ? businessBasePrice + professionalAdditionalPrice
    : businessBasePrice;

  const getDataPlans = () => {
    return [
      {
        id: 3,
        title: 'Profesional',
        description: (
          <span className="text-blue font-medium">
            Para profesionales y creadores: reserva de turnos, capacitaciones o descargables
          </span>
        ),
        price: professionalPrice,
        referredPrice: professionalPrice - referralDiscount,
        footer: <div className="h-[46px]" />,
        tax: 'IVA incluido.'
      },
      {
        id: 2,
        title: 'Negocio',
        description: (
          <span className="text-blue font-medium">
            Para centros, salones y consultorios. Gestión integral de turnos para equipos.
          </span>
        ),
        price: businessPlan,
        referredPrice: businessPlan - referralDiscount,
        footer: (
          <Row
            align="center"
            justify={isMobileSize ? 'flex-start' : 'center'}
            gap={16}
            className="w-full bg-white rounded-sm h-[46px] p-4 sm:text-sm mb-2"
          >
            <div
              onClick={() => setNumberOfProfessionals(numberOfProfessionals - 1)}
              className={classNames('cursor-pointer shadow-md py-1 px-3 border border-[#E9E9E9] rounded', {
                'pointer-events-none cursor-not-allowed bg-[#E9E9E9]': numberOfProfessionals <= 2,
                'cursor-pointer': numberOfProfessionals > 2
              })}
            >
              <FontAwesomeIcon icon={faMinus} size="1x" color="#000" />
            </div>
            <span className="font-medium text-sm sm:text-base">{numberOfProfessionals} profesionales</span>
            <div
              onClick={() => setNumberOfProfessionals(numberOfProfessionals + 1)}
              className={classNames('cursor-pointer shadow-md py-1 px-3 border border-[#E9E9E9] rounded', {
                invisible: numberOfProfessionals >= 15,
                visible: numberOfProfessionals < 15
              })}
            >
              <FontAwesomeIcon icon={faPlus} size="1x" color="#000" />
            </div>
          </Row>
        ),
        tax: 'IVA incluido.'
      }
    ];
  };

  const dataPlans = getDataPlans();

  const getSymbolFromCountry = (country: string) => {
    switch (country) {
      case 'Chile':
        return 'CLP';
      case 'Uruguay':
        return 'U$';
      default:
        return '$';
    }
  };

  return (
    <section>
      <div className="flex flex-col gap-6 justify-center sm:flex-row">
        {dataPlans.map((plan) => (
          <div key={plan.id} className="w-full h-auto sm:w-[350px] relative">
            <Column
              justify="space-between"
              className={classNames(
                'p-4 h-full bg-white border border-[#DADCE0] shadow-lg rounded-3xl sm:rounded-none'
              )}
            >
              <div className="flex flex-row sm:flex-col w-full justify-between px-2 sm:px-0">
                <Column gap={5}>
                  <h2 className="text-3xl">{plan.title}</h2>
                  <p className="font-medium text-base sm:h-[72px]">{plan.description}</p>
                  {isMobileSize && plan.id === 2 && plan.footer}
                  <Row>
                    <p className="font-bold text-base mt-2">{getSymbolFromCountry(country)}</p>
                    <Row align="center">
                      <p className="text-5xl font-bold">
                        {isReferred
                          ? plan.referredPrice!.toLocaleString('es-AR')
                          : plan.price.toLocaleString('es-AR')}
                      </p>
                      <span className="text-base font-bold mt-5">/mes</span>
                    </Row>
                  </Row>
                  <Row gap={16} align="baseline">
                    {isReferred ? (
                      <>
                        <p className="text-[#FF4658] text-lg line-through">
                          {plan.price.toLocaleString('es-AR')}/mes
                        </p>
                        <p className="text-[#868686] text-sm">{plan.tax}</p>
                      </>
                    ) : (
                      <p className="text-[#868686] text-sm">{plan.tax}</p>
                    )}
                  </Row>
                  {!isMobileSize && plan.footer}
                </Column>
                {isMobileSize && (
                  <button
                    className="self-end"
                    onClick={() => {
                      setLoading(true);
                      onSubmit(plan.id, plan.id !== 2 ? undefined : numberOfProfessionals);
                      setPlanSelected(plan.id);
                    }}
                    disabled={isLoading || loading}
                  >
                    {(isLoading || loading) && planSelected === plan.id ? (
                      <Spinner color="#0072FB" size={48} />
                    ) : (
                      <FontAwesomeIcon icon={faChevronCircleRight} size="3x" color="#0072FB" />
                    )}
                  </button>
                )}
              </div>
              {!isMobileSize && (
                <button
                  onClick={() => {
                    setLoading(true);
                    onSubmit(plan.id, plan.id !== 2 ? undefined : numberOfProfessionals);
                    setPlanSelected(plan.id);
                  }}
                  disabled={isLoading || loading}
                  className={classNames(
                    'w-full h-12 transition-colors duration-300 font-medium border text-[#0072FB] border-[#0072FB] hover:bg-[#0072FB] hover:text-white',
                    {
                      'bg-blue': isLoading && planSelected === plan.id,
                      'bg-white': !isLoading || (isLoading && planSelected !== plan.id)
                    }
                  )}
                >
                  {(isLoading || loading) && planSelected === plan.id ? (
                    <Spinner color="#fff" size={20} />
                  ) : (
                    'Empezá gratis'
                  )}
                </button>
              )}
            </Column>
          </div>
        ))}
      </div>
      <div className="flex flex-col bg-blue/10 max-w-[720px] w-full items-center m-auto mt-6 py-2 px-2 border shadow-md border-[#DADCE0]">
        <Row gap={16} align="center" className="py-2 px-4 text-lg w-full">
          <FontAwesomeIcon icon={faCheck} color="#000" />
          <span className="text-lg">
            ¡Los planes cuentan con <b>reservas de turnos ilimitadas, sin comisiones!</b>
          </span>
        </Row>
        <Row gap={16} align="center" className="py-2 px-4 text-lg w-full">
          <FontAwesomeIcon icon={faCheck} color="#000" />
          <span className="text-lg">
            Contás con 14 días de prueba gratis para familiarizarte con la plataforma
          </span>
        </Row>
      </div>
    </section>
  );
};
