import React, { FunctionComponent, ChangeEvent, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Checkbox, Tooltip, Row } from '@/components';
import { Icon, IconName } from '../Icon';
import { theme } from '../App';

type VariantType = 'input' | 'textarea' | 'password';

type InputProps = {
  variant?: VariantType;
  shadow?: boolean;
  pattern?: string;
  placeholder?: string;
  value?: string | number;
  name?: string;
  onChange: ((e: ChangeEvent<HTMLInputElement>) => void) | ((e: ChangeEvent<HTMLTextAreaElement>) => void);
  reset?: () => void;
  helpText?: string | JSX.Element;
  maxLength?: number;
  showCount?: boolean;
  showCheckboxToDisable?: boolean;
  inputId: string;
  error?: string;
  type?: string;
  postFix?: string | JSX.Element;
  disabled?: boolean;
  onClick?: () => void;
  rightIcon?: IconName;
  leftIcon?: IconName;
  autoComplete?: string;
  tooltipText?: string;
  tooltipPosition?: 'right' | 'left' | 'top' | 'bottom';
  fullWidth?: boolean;
  minDate?: string;
  rows?: number;
  capitalize?: boolean;
  checkboxLabel?: string;
  handleCheckboxClick?: (checkboxState: boolean) => void;
  placeholderInput?: string;
  colorText?: string;
  inputIcon?: IconName;
};

export const Input: FunctionComponent<InputProps> = ({
  variant = 'input',
  pattern,
  placeholder,
  name,
  value,
  onChange,
  reset,
  helpText,
  maxLength,
  showCount,
  showCheckboxToDisable,
  inputId,
  error,
  postFix,
  disabled = false,
  onClick,
  type = 'text',
  rightIcon,
  leftIcon,
  shadow,
  autoComplete,
  tooltipText,
  tooltipPosition = 'bottom',
  minDate,
  fullWidth,
  rows = 200,
  capitalize = true,
  checkboxLabel,
  handleCheckboxClick,
  placeholderInput = '',
  inputIcon,
  colorText
}) => {
  // This state is only used to disable the input when the checkbox is checked
  const [isDisabled, setDisabled] = useState<boolean>(disabled);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const toggleDisabled = () => setDisabled(!isDisabled);

  const validateOnChange = (e: ChangeEvent<HTMLInputElement & HTMLTextAreaElement>) => {
    if (e.target.validity.valid) onChange(e);
  };

  const inputValuePreventChange = (e: any) => {
    e.target.blur();
    e.stopPropagation();
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  useEffect(() => {
    if (isDisabled) reset?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDisabled]);

  useEffect(() => setDisabled(disabled), [disabled]);

  const id = `${inputId}-${variant === 'input' ? 'i' : variant === 'textarea' ? 'ta' : 'p'}`;
  const inputProps = {
    placeholder: placeholderInput,
    value,
    onChange: validateOnChange,
    name,
    maxLength,
    type: variant === 'password' && !showPassword ? 'password' : type,
    pattern,
    disabled: showCheckboxToDisable ? isDisabled : disabled,
    id,
    min: minDate,
    error: !!error,
    onClick,
    autoComplete,
    rows
  };

  const showBottom = helpText || showCount || error;

  return (
    <div style={fullWidth ? { width: '100%' } : {}}>
      <InputContainer
        disabled={showCheckboxToDisable ? isDisabled : disabled}
        variant={variant}
        error={!!error}
        shadow={shadow}
        rows={rows}
      >
        {variant === 'input' || variant === 'password' ? (
          <>
            <Row>
              {inputIcon && (
                <Icon name={inputIcon} width="22px" height="22px" color="#20B038" marginRight={'5px'} />
              )}
            </Row>
            <StyledInput
              {...inputProps}
              onWheel={inputValuePreventChange}
              color={colorText}
              placeholder={showCheckboxToDisable ? '' : placeholder}
            />
            {type === 'date' && (
              <Icon
                className="mr-[-4px]"
                name="calendar"
                width={'15px'}
                height={'15px'}
                marginRight={'5px'}
                color={theme.colors.grey[700]}
              />
            )}
          </>
        ) : (
          <TextArea {...inputProps} color={colorText} />
        )}
        <Label capitalize={capitalize} htmlFor={id}>
          {leftIcon && (
            <Icon
              name={leftIcon}
              width={'11px'}
              height={'11px'}
              marginRight={'5px'}
              color={theme.colors.grey[700]}
            />
          )}
          {placeholder}
        </Label>
        {showCheckboxToDisable && (
          <CheckboxContainer>
            <Checkbox
              enable={isDisabled}
              onClick={() => {
                handleCheckboxClick !== undefined && handleCheckboxClick(!isDisabled);
                toggleDisabled();
              }}
            />
            {checkboxLabel && <CheckboxLabel>{checkboxLabel}</CheckboxLabel>}
          </CheckboxContainer>
        )}
        {postFix && <PostFix>{postFix}</PostFix>}
        {rightIcon && <Icon name={rightIcon} width={'11px'} height={'11px'} marginLeft={'7px'} />}
        {variant === 'password' && (
          <PasswordIcon onClick={() => setShowPassword(!showPassword)}>
            <Icon name={showPassword ? 'eye_slash' : 'eye'} width="14px" height="14px" marginLeft="7px" />
          </PasswordIcon>
        )}
      </InputContainer>
      {showBottom && (
        <Flex>
          {(helpText || error) && (
            <BottomText error={!!error} showCount={showCount || false}>
              {error || helpText}
              {tooltipText && <Tooltip position={tooltipPosition} content={tooltipText} marginLeft="6px" />}
            </BottomText>
          )}
          {maxLength && showCount && (
            <CountCharacters left={!helpText}>
              {value?.toString().length || 0}/{maxLength}
            </CountCharacters>
          )}
        </Flex>
      )}
    </div>
  );
};

const InputContainer = styled.div<{
  disabled: boolean;
  variant: VariantType;
  error: boolean;
  shadow?: boolean;
  rows?: number;
}>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem;
  height: ${({ variant, rows }) => (variant === 'textarea' ? rows : 40)}px;
  border-radius: 4px;
  background: #fff;
  position: relative;
  ${({ error, theme }) =>
    error
      ? css`
          border: 1px solid ${theme.colors.red[200]};
        `
      : css`
          border: 1px solid rgba(0, 0, 0, 0.36);
          &:focus-within {
            border: 1px solid ${theme.colors.blue[500]};
            border-radius: 4px;
          }
        `};
  ${({ disabled }) =>
    disabled &&
    css`
      background: ${theme.colors.whiteSmoke};
      border: 1px solid transparent;
      ${Label} {
        background: none;
      }
    `};
  ${({ shadow }) =>
    shadow &&
    css`
      border: 1px solid ${theme.colors.whiteSmoke};
      box-sizing: border-box;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
    `}
`;

const getInputStyles = (error: boolean) => css`
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 1rem;
  &:focus + label {
    top: -0.15rem;
    left: 0.8rem;
    color: ${({ theme }) => (error ? theme.colors.red[200] : theme.colors.blue[500])};
    font-size: 0.73rem;
    font-weight: 500;
  }
  &:not(:placeholder-shown) {
    &:not(:focus) {
      + label {
        top: -0.15rem;
        left: 0.8rem;
        font-size: 0.73rem;
        font-weight: 500;
        color: ${({ theme }) => (error ? theme.colors.red[200] : theme.colors.black)};
      }
    }
  }
`;

const PostFix = styled.span`
  font-size: 15px;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.grey[700]};
  padding-left: 8px;
`;

const Label = styled.label<{ capitalize: boolean }>`
  position: absolute;
  top: 50%;
  left: 1rem;
  transform: translateY(-50%);
  padding: 0 0.25rem;
  background-color: inherit;
  transition: 0.2s;
  pointer-events: none;
  color: ${({ theme }) => theme.colors.grey[700]};
  text-transform: ${({ capitalize }) => (capitalize ? 'capitalize' : 'none')};
`;

const StyledInput = styled.input<{ error: boolean; color?: string }>`
  ${({ error }) => getInputStyles(error)};
  ${({ color }) => (color ? `color: ${color}` : '')};
  width: 100%;
  &:disabled {
    cursor: not-allowed;
  }
`;

const TextArea = styled.textarea<{ error: boolean; color?: string }>`
  ${({ error }) => getInputStyles(error)};
  ${({ color }) => (color ? `color: ${color}` : '')};
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 0.4rem;
`;

const BottomText = styled.span<{ error: boolean; showCount: boolean }>`
  display: flex;
  flex-direction: row;
  min-width: 15rem;
  color: ${({ theme, error }) => (error ? theme.colors.red[200] : theme.colors.grey[700])};
  font-size: 0.73rem;
  margin-left: 1rem;
`;

const CountCharacters = styled.span<{ left: boolean }>`
  display: flex;
  color: ${({ theme }) => theme.colors.blue[500]};
  font-size: 0.73rem;
  ${({ left }) =>
    left &&
    css`
      width: 100%;
      justify-content: flex-end;
    `}
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  align-self: center;
  z-index: 1;
`;

const CheckboxLabel = styled.span`
  margin-left: 4px;
  color: ${({ theme }) => theme.colors.grey[700]};
`;

const PasswordIcon = styled.div`
  position: absolute;
  cursor: pointer;
`;
