import { useState, ChangeEvent, createContext, FunctionComponent, useContext, useEffect } from 'react';
import { LabelValue } from '@/types';
import {
  useForm,
  DeepMap,
  FieldValues,
  FieldError,
  Control,
  UseFormHandleSubmit,
  UseFormReset,
  UseFormSetValue,
  UseFormWatch,
  SubmitHandler
} from 'react-hook-form';
import { buildSelectOptions, SelectOptionProps } from '@/components/Select';
import { useMutation, useQuery } from 'react-query';
import useLog from '@/hooks/useLog';
import { useClient, useDebounce } from '@/hooks';
import {
  ErrorI,
  GetClientBaseI,
  GetPlacesI,
  GetUniqueEventI,
  PostUniqueEventI
} from '@/types/cyclone/requests';
import {
  AgoraPlanNameE,
  BookingI,
  MeetTypeE,
  ServiceModalityE,
  UniqueEventI,
  UniqueEventsInvitations,
  UniqueEventTicketI,
  WalletTypeE
} from '@/types/cyclone/models';
import {
  CANCELLATION_OPTIONS,
  DEFAULT_AGORA_IMAGE,
  FAQItemI,
  Image,
  MINUTES_OPTIONS,
  PlaceType
} from '../utils';
import { useAuth, useConfirmation } from '@/contexts';
import dayjs from 'dayjs';
import { blobToBase64, urlImageToFile } from '@/utils';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { REGEX_VALID_EMAIL } from '@/constants/regex';
import { getMinutesBetweenDates } from '@/utils/schedule';
import { trackGenericEvent } from '@/analytics';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

type UniqueEventContextType = {
  errors: DeepMap<FieldValues, FieldError>;
  control: Control<UniqueEventFormStateI>;
  modality: ServiceModalityE;
  meetType: MeetTypeE | undefined;
  timeLimit: LabelValue | undefined;
  selectOptionsTimeLimit: SelectOptionProps[];
  selectOptionsCancellationPolicy: SelectOptionProps[];
  handleSubmit: UseFormHandleSubmit<FieldValues>;
  coverImage: string | undefined;
  setCoverImage: (file: string | undefined) => void;
  handleCoverImage: (image?: string) => void;
  handleImages: (images: string[]) => void;
  aboutErrors: Record<string, boolean>;
  handleErrors: (event: UniqueEventI | undefined) => boolean;
  ticketEditionMode: boolean;
  locationValue: string;
  handleLocationValue: (e: ChangeEvent<HTMLInputElement>) => void;
  places: PlaceType[];
  isLoadingPlaces: boolean;
  selectedPlace: PlaceType | undefined;
  handleSelectPlace: (place: PlaceType) => void;
  reset: UseFormReset<UniqueEventFormStateI>;
  handleModality: (modality: ServiceModalityE) => void;
  handleMeetType: (meetType: MeetTypeE) => void;
  handleTimeLimit: (timeLimit: LabelValue) => void;
  setValue: UseFormSetValue<UniqueEventFormStateI>;
  cancellationPolicies: LabelValue | undefined;
  selectReservationTimeLimitOptions: SelectOptionProps[];
  reservationTimeLimitOption: LabelValue | undefined;
  setCancellationPolicies: (cancellationPolicies: LabelValue | undefined) => void;
  handlePolicy: (policy: LabelValue) => void;
  description: string;
  handleDescription: (description: string) => void;
  handlePassword: (password: string) => void;
  watch: UseFormWatch<UniqueEventFormStateI>;
  imageGallery: Image[];
  setImageGallery: React.Dispatch<React.SetStateAction<Image[]>>;
  isInternationalPaymentsEnabled: boolean;
  event: UniqueEventI | undefined;
  isLoadingEvent: boolean;
  isLoadingBookings: boolean;
  faqs: FAQItemI[];
  setFaqs: (items: FAQItemI[]) => void;
  selectedTab: string;
  setSelectedTab: (tab: string) => void;
  bookings: BookingI[] | undefined;
  refetchBookings: () => void;
  confirmBeforeSubmit: () => void;
  toggleCancellationPolicy: boolean;
  setToggleCancellationPolicy: React.Dispatch<React.SetStateAction<boolean>>;
  toggleUniqueEventFee: boolean;
  setToggleUniqueEventFee: React.Dispatch<React.SetStateAction<boolean>>;
  toggleRefundAvailable: boolean;
  setToggleRefundAvailable: React.Dispatch<React.SetStateAction<boolean>>;
  isMutationLoading: boolean;
  password: string;
  toggleMaxTicketsQuantity: boolean;
  setToggleMaxTicketsQuantity: React.Dispatch<React.SetStateAction<boolean>>;
  maxTicketsQuantity: number;
  handleMaxTicketsQuantity: (quantity: number) => void;
  handleToggleCancellationPolicy: (toggle: boolean) => void;
  onKeyDownInvitation: (e: any) => void;
  handleCancelInvitation: (tag: EmailTag) => void;
  handlePasteInvitation: (event: React.ClipboardEvent<HTMLInputElement>) => void;
  emailTags: EmailTag[];
  inputInvitation: string;
  setInputInvitation: React.Dispatch<React.SetStateAction<string>>;
  selectInvitationTicketsOptions: SelectOptionProps[];
  selectTicketInvitation: LabelValue | undefined;
  handleSubmitInvitation: (req?: any) => void;
  handleQuantityInvitation: (quantity: number) => void;
  quantityInvitation: number;
  mutationSendInvitationIsLoading: boolean;
  getSlotsTicket: () => number | undefined;
  handleToggleMaxTicketsQuantity: (toggle: boolean) => void;
  isMultipleDates: boolean;
  handleIsMultipleDates: (isMultipleDates: boolean) => void;
  toggleIsUserPhoneRequired: boolean;
  setToggleIsUserPhoneRequired: (value: boolean) => void;
  isInfoMode: boolean;
  clients: GetClientBaseI | undefined;
};

interface EmailTag {
  value: string;
  isValid: boolean;
}

export interface UniqueEventFormStateI {
  tickets: {
    name: string;
    modality: string;
    price: number;
    price_usd: number;
    slots: number;
    isSave: boolean;
    is_hidden: boolean;
  }[];
  questions: QuestionRequestI[];
  eventName: string;
  meetLink: string;
  zoomLink: string;
  eventDates: EventDateI[];
}

interface EventDateI {
  date: string;
  startTime: string;
  endTime: string;
  isSave: boolean;
}

interface QuestionRequestI {
  id?: number;
  question: string;
  is_required: boolean;
  isSave: boolean;
}

const UniqueEventContext = createContext<UniqueEventContextType>({} as UniqueEventContextType);

type Props = {
  children: React.ReactNode;
  id?: string;
  isOnlyInfo: boolean;
};

export const UniqueEventProvider: FunctionComponent<Props> = ({ children, id, isOnlyInfo }) => {
  const { client } = useClient();
  const navigate = useNavigate();
  const { confirm } = useConfirmation();
  const { reloadSession, session } = useAuth();
  const { logAndNotify } = useLog();

  const isEventPlan = session?.vendor?.plan_name === AgoraPlanNameE.EVENT;

  const timezone = session?.vendor?.timezone || 'America/Argentina/Buenos_Aires';

  const isInfoMode = isOnlyInfo;
  const isEdit = !!id;

  const {
    data: event,
    isFetching: isLoadingEvent,
    refetch: refetchEvent
  } = useQuery(
    ['unique_event', id],
    async () =>
      await client<GetUniqueEventI>(`me/vendor/unique_events/${id}`, 'GET', {
        isAuthRequired: true
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: isEdit,
      onSuccess: (event) => {
        handleIsMultipleDates(event.unique_events_schedules.length > 1);
        setListTickets(event.unique_events_tickets);
        const tickets = event.unique_events_tickets
          .filter((ticket) => ticket.parent_ticket_id === null)
          .map((ticket) => {
            return {
              ...ticket,
              slots: ticket.total_slots,
              isSave: true,
              is_hidden: ticket.is_hidden
            };
          });

        reset({
          eventName: event.name,
          ...(event.address_details && { addressDetails: event.address_details }),
          meetLink: event.meet_type === 'google_meet' ? event.meet_link : undefined,
          zoomLink: event.meet_type === 'zoom' ? event.meet_link : undefined,
          eventDates: event.unique_events_schedules.map((schedule) => ({
            date: dayjs(schedule.start_at).tz(timezone).format('YYYY-MM-DD'),
            startTime: dayjs(schedule.start_at).tz(timezone).format('HH:mm'),
            endTime: dayjs(schedule.end_at).tz(timezone).format('HH:mm'),
            isSave: true
          })),
          tickets,
          questions:
            event?.custom_questions?.map((item) => ({
              id: item.id,
              question: item.question,
              isSave: true,
              is_required: item.is_required
            })) || []
        });

        setFaqs(event.faqs || []);

        handleToggleMaxTicketsQuantity(event.is_limited_tickets);

        handleMaxTicketsQuantity(event.max_tickets_quantity);

        handleImages(event.images_gallery);

        handleCoverImage(event.cover_url);

        handleDescription(event.description);

        handlePassword(event.password);

        handleModality(event.modality);

        handleMeetType(event.meet_type);

        handleIsPhoneNumberRequired(event.is_user_phone_required);

        setToggleRefundAvailable(event.is_refund_available);

        handleTimeLimit({
          value: event.booking_until_at_minutes.toString(),
          label: MINUTES_OPTIONS.find(({ value }) => value === event.booking_until_at_minutes.toString())!
            .label
        });

        handleToggleUniqueEventFee(event.is_transfer_fee_available);

        handleToggleCancellationPolicy(event.is_policy_available);

        handlePolicy({
          value: event.policy_refund_mins ? event.policy_refund_mins.toString() : '',
          label: event.policy_refund_mins
            ? MINUTES_OPTIONS.find(({ value }) => value === event.policy_refund_mins.toString())!.label
            : ''
        });
      }
    }
  );

  useEffect(() => {
    if (event !== undefined) {
      handleSelectPlace({
        address: event.address || ''
      });
      setReservationTimeLimitOption(
        event.is_booking_until_at_after ? CANCELLATION_OPTIONS[1] : CANCELLATION_OPTIONS[0]
      );
    }
  }, [event]);

  const [description, setDescription] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [faqs, setFaqs] = useState<FAQItemI[]>([]);
  const [modality, setModality] = useState<ServiceModalityE>(ServiceModalityE.LOCAL);
  const [listTickets, setListTickets] = useState<UniqueEventTicketI[]>([]);
  const [meetType, setMeetType] = useState<MeetTypeE | undefined>(undefined);
  const [timeLimit, setTimeLimit] = useState<LabelValue | undefined>(
    MINUTES_OPTIONS.find(({ value }) => value === '30')
  );
  const [isMultipleDates, setIsMultipleDates] = useState<boolean>(false);
  const [cancellationPolicies, setCancellationPolicies] = useState<LabelValue | undefined>(
    MINUTES_OPTIONS.find(({ value }) => value === '30')
  );
  const [reservationTimeLimitOption, setReservationTimeLimitOption] = useState<LabelValue | undefined>(
    CANCELLATION_OPTIONS.find(({ value }) => value === 'before')
  );
  const [toggleRefundAvailable, setToggleRefundAvailable] = useState(true);
  const [toggleCancellationPolicy, setToggleCancellationPolicy] = useState(false);
  const [toggleUniqueEventFee, setToggleUniqueEventFee] = useState(true);
  const [toggleMaxTicketsQuantity, setToggleMaxTicketsQuantity] = useState(false);
  const [maxTicketsQuantity, setMaxTicketsQuantity] = useState<number>(1);
  const [locationValue, setLocationValue] = useState<string>('');
  const [selectedPlace, setSelectedPlace] = useState<PlaceType | undefined>(undefined);
  const [toggleIsUserPhoneRequired, setToggleIsUserPhoneRequired] = useState<boolean>(false);
  const [fetchLocations, setFetchLocations] = useState<boolean>(false);

  const toggleFetchLocations = () => setFetchLocations(!fetchLocations);
  const locationDebounce = useDebounce(toggleFetchLocations, 300);

  const handleLocationValue = (e: ChangeEvent<HTMLInputElement>) => {
    if (selectedPlace) {
      setSelectedPlace(undefined);
      setLocationValue('');
    } else setLocationValue(e.target.value);

    locationDebounce();
  };

  const handleSelectPlace = (place: PlaceType) => {
    setLocationValue(place.address);
    setSelectedPlace(place);
  };

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    watch
  } = useForm({
    defaultValues: {
      tickets: [
        { name: '', price: 0, price_usd: 0, slots: 0, modality: '', isSave: false, is_hidden: false }
      ],
      questions: [{ question: '', isSave: false, is_required: false }],
      eventName: '',
      meetLink: '',
      zoomLink: '',
      eventDates: [{ date: '', startTime: '18:00', endTime: '19:00', isSave: false }]
    }
  });

  const [coverImage, setCoverImage] = useState<string | undefined>(undefined);
  const handleCoverImage = (image?: string) => setCoverImage(image || undefined);

  const { data: locationResponses, isFetching: isLoadingPlaces } = useQuery(
    ['location', fetchLocations],
    async () =>
      await client<GetPlacesI>(`google/places/${locationValue}?country=${session?.vendor?.country}`),
    {
      enabled: !!locationValue,
      retry: false,
      refetchOnWindowFocus: false
    }
  );

  useEffect(() => {
    if (!coverImage) setCoverImage(DEFAULT_AGORA_IMAGE);
  }, [coverImage]);

  const places: PlaceType[] =
    locationResponses?.map(({ description }) => ({
      address: description
    })) || [];

  const getItems = (count: number): Image[] =>
    Array.from({ length: count }, (v, k) => k).map((k) => ({
      id: `item-${k}`
    }));
  const [imageGallery, setImageGallery] = useState<Image[]>(getItems(5));
  const handleImages = (images?: string[]) => {
    if (images) {
      const presetImages = imageGallery.map((position, index) => ({
        ...position,
        url: images[index]
      }));

      setImageGallery(presetImages);
    }
  };

  const {
    data: bookings,
    refetch: refetchBookings,
    isFetching: isLoadingBookings
  } = useQuery(
    ['bookings', 'unique_event', id],
    async () =>
      await client<BookingI[]>(`bookings/unique_events/${id}`, 'GET', {
        isAuthRequired: true
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: isEdit
    }
  );

  const isInternationalPaymentsEnabled =
    session?.vendor?.payment_gateways?.includes(WalletTypeE.STRIPE) || false;

  const selectOptionsTimeLimit = buildSelectOptions(MINUTES_OPTIONS, timeLimit, setTimeLimit);
  const selectOptionsCancellationPolicy = buildSelectOptions(
    MINUTES_OPTIONS,
    cancellationPolicies,
    setCancellationPolicies
  );

  const selectReservationTimeLimitOptions = buildSelectOptions(
    CANCELLATION_OPTIONS,
    reservationTimeLimitOption,
    setReservationTimeLimitOption
  );

  const handleModality = (modality: ServiceModalityE) => setModality(modality);
  const handleMeetType = (meetType: MeetTypeE | undefined) => setMeetType(meetType);
  const handleTimeLimit = (timeLimit: LabelValue) => setTimeLimit(timeLimit);
  const handleDescription = (description: string) => setDescription(description);
  const handlePassword = (password: string) => setPassword(password);
  const handleIsMultipleDates = (isMultipleDates: boolean) => setIsMultipleDates(isMultipleDates);
  const handlePolicy = (policy: LabelValue) => setCancellationPolicies(policy);
  const handleMaxTicketsQuantity = (quantity: number) => setMaxTicketsQuantity(quantity);
  const handleToggleUniqueEventFee = (toggle: boolean) => setToggleUniqueEventFee(toggle);
  const handleToggleMaxTicketsQuantity = (toggle: boolean) => {
    setToggleMaxTicketsQuantity(toggle);
    if (toggle) handleMaxTicketsQuantity(4);
  };
  const handleToggleCancellationPolicy = (toggle: boolean) => {
    setToggleCancellationPolicy(toggle);
    if (!toggle) setCancellationPolicies(undefined);
    else if (cancellationPolicies === undefined && toggle) setCancellationPolicies(MINUTES_OPTIONS[0]);
  };
  const handleIsPhoneNumberRequired = (value: boolean) => setToggleIsUserPhoneRequired(value);

  const [selectedTab, setSelectedTab] = useState('contenido');

  const ticketEditionMode = watch('tickets').some((ticket: any) => !ticket.isSave);

  // ---------------------------------- ERRORS ----------------------------------

  const initialSelectErrors = {
    meetType: false,
    modality: false,
    location: false,
    description: false,
    schedule: false,
    password: false,
    meetLink: false,
    zoomLink: false,
    eventDates: false,
    eventName: false
  };

  const [aboutErrors, setAboutErrors] = useState<Record<string, boolean>>(initialSelectErrors);

  const handleErrors = () => {
    const { meetLink, zoomLink, eventName, eventDates, tickets } = watch();

    // Filter empty dates
    const filteredEventDates = eventDates.filter(
      (eventDate: any) => eventDate.date !== '' && eventDate.startTime !== '' && eventDate.endTime !== ''
    );

    const errors = [
      {
        meetType: {
          boolean:
            modality === ServiceModalityE.VIRTUAL || modality === ServiceModalityE.OPEN ? !meetType : false,
          message: 'Selecciona un tipo de encuentro'
        }
      },
      {
        eventName: {
          boolean: !eventName || eventName.length < 10,
          message: `Ingresá el nombre ${
            isEventPlan ? 'del evento' : 'de la capacitación'
          } (mínimo 10 caracteres)`
        }
      },
      {
        meetLink: {
          boolean: modality === ServiceModalityE.VIRTUAL && meetType === MeetTypeE.GOOGLE_MEET && !meetLink,
          message: 'Ingresá el link de la reunión'
        }
      },
      {
        zoomLink: {
          boolean: modality === ServiceModalityE.VIRTUAL && meetType === MeetTypeE.ZOOM && !zoomLink,
          message: 'Ingresá el link de la reunión'
        }
      },
      {
        modality: {
          boolean: !modality,
          message: 'Selecciona una modalidad'
        }
      },
      {
        location: {
          boolean: modality === ServiceModalityE.VIRTUAL ? false : !selectedPlace,
          message: 'Seleccioná una ubicación'
        }
      },
      {
        password: {
          boolean: password?.length >= 1 && password?.length < 4,
          message: 'La contraseña debe tener al menos 4 caracteres'
        }
      },
      {
        eventDates: {
          boolean: filteredEventDates.length === 0,
          message: 'Ingresá una fecha válida'
        }
      },
      {
        tickets: {
          boolean:
            tickets.length === 0 ||
            (tickets.length >= 1 && tickets.some((ticket) => ticket.slots === 0)) ||
            tickets.some((ticket) => ticket.isSave === false),
          message:
            tickets.length >= 1 && tickets.some((ticket) => ticket.slots === 0)
              ? 'No puedes tener entradas con cantidad cero'
              : tickets.some((ticket) => ticket.isSave === false)
              ? 'Debes guardar tus entradas primero'
              : 'Debes agregar al menos una entrada'
        }
      }
    ];

    const errorsObject = Object.values(errors).reduce((acc, error) => {
      const key = Object.keys(error)[0];
      const value = Object.values(error)[0];
      return {
        ...acc,
        [key]: value.boolean
      };
    }, initialSelectErrors);

    setAboutErrors(errorsObject);

    const hasErrors = Object.values(errors).some((error) => Object.values(error)[0].boolean);

    if (hasErrors) {
      const errorMessage = Object.values(errors)
        .filter((error) => Object.values(error)[0].boolean)
        .map((error) => Object.values(error)[0].message);
      toast.error(errorMessage.join('\r\n'));
      return true;
    } else {
      return false;
    }
  };

  // ---------------------------------- END OF ERRORS ----------------------------------

  // ---------------------------------- INVITATIONS ----------------------------------

  const [inputInvitation, setInputInvitation] = useState('');
  const [emailTags, setEmailTags] = useState<EmailTag[]>([]);
  const [selectTicketInvitation, setSelectTicketInvitation] = useState<LabelValue | undefined>(undefined);
  const [quantityInvitation, setQuantityInvitation] = useState<number>(0);

  const onKeyDownInvitation = (e: any) => {
    const { key } = e;
    const normalizedInput = inputInvitation.replace(/\s+/g, '').toLowerCase();

    const isValid = REGEX_VALID_EMAIL.test(normalizedInput);

    if (
      (key === ',' || key === 'Enter' || key === ';') &&
      normalizedInput.length &&
      !emailTags.map((t) => t.value).includes(normalizedInput)
    ) {
      e.preventDefault();

      const tag = {
        value: normalizedInput,
        isValid
      };
      const newTags = [...emailTags, tag];
      setEmailTags(newTags);
      setInputInvitation('');
    } else if (key === 'Backspace' && normalizedInput.length === 0) {
      setEmailTags(emailTags.slice(0, emailTags.length - 1));
    }
  };

  const handleCancelInvitation = (tag: EmailTag) => {
    setEmailTags(emailTags.filter((t) => t.value !== tag.value));
    setInputInvitation('');
  };

  const handlePasteInvitation = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const inputValue = event.clipboardData.getData('text');
    const formatInput = inputValue.replace(/[;\s]/g, ',').split(',');

    const emails = formatInput.filter((email) => {
      return email !== '';
    });

    if (emails.length > 0) {
      event.preventDefault();
      setInputInvitation('');
      const newTags: EmailTag[] = [];
      emails.forEach((email) => {
        const normalizedEmail = email.toLowerCase();
        if (!emailTags.map((t) => t.value).includes(normalizedEmail)) {
          newTags.push({
            value: normalizedEmail,
            isValid: REGEX_VALID_EMAIL.test(normalizedEmail)
          });
        }
      });
      setEmailTags([...emailTags, ...newTags]);
    }
  };

  const getAvailableTags = () => emailTags.filter((t) => t.isValid);

  const buildTicketOptions = () => {
    const ticketOptions: LabelValue[] = [];
    if (listTickets) {
      listTickets.forEach((ticket) => {
        ticketOptions.push({ value: ticket.id.toString(), label: ticket.name });
      });
    }
    return ticketOptions;
  };

  const selectInvitationTicketsOptions = buildSelectOptions(
    buildTicketOptions(),
    selectTicketInvitation,
    setSelectTicketInvitation
  );

  const handleQuantityInvitation = (quantity: number) => setQuantityInvitation(quantity);

  const getSlotsTicket = () => {
    if (selectTicketInvitation) {
      const ticketSelected = listTickets.find(
        (ticket) => ticket.id.toString() === selectTicketInvitation.value
      );
      return ticketSelected?.slots;
    }
    return;
  };

  const { data: clients, refetch: refetchClients } = useQuery(
    ['client_base'],
    async () =>
      await client<GetClientBaseI>(`me/vendor/client_base`, 'GET', {
        isAuthRequired: true
      }),
    {
      retry: false,
      refetchOnWindowFocus: false
    }
  );

  const mutationSendInvitation = useMutation<UniqueEventsInvitations>(
    (data) =>
      client(`unique_events/${id}/invitations`, 'POST', {
        isAuthRequired: true,
        data: data
      }),
    {
      onSuccess: () => {
        toast.success('Invitaciones enviadas');
        reloadSession();
        setSelectedTab('participantes');
        refetchBookings();
        setEmailTags([]);
        refetchClients();
      }
    }
  );

  const mutationSendInvitationIsLoading = mutationSendInvitation.isLoading;

  const handleSubmitInvitation = (req?: any) => {
    if (!selectTicketInvitation) return toast.error('Seleccioná un tipo de entrada');
    if (quantityInvitation === 0) return toast.error('Ingresá la cantidad de entradas');
    const unique_event_ticket_id = parseInt(selectTicketInvitation.value);
    const quantity = quantityInvitation;

    if (req) {
      const email = req.email;
      if (quantity > getSlotsTicket()!) return toast.error('No hay suficientes entradas');
      const invitation: UniqueEventsInvitations = {
        emails: email,
        unique_event_ticket_id,
        quantity,
        first_name: req.first_name,
        last_name: req.last_name
      };
      mutationSendInvitation.mutate(invitation as any);
    } else {
      const availableTags = getAvailableTags();
      if (availableTags.length === 0) return toast.error('Ingresá al menos un correo electrónico válido');
      const emails = availableTags.map((t) => t.value);
      if (emails.length * quantity > getSlotsTicket()!)
        return toast.error('No hay suficientes entradas para la cantidad de correos ingresados');

      const invitation: UniqueEventsInvitations = {
        emails,
        unique_event_ticket_id,
        quantity
      };
      mutationSendInvitation.mutate(invitation as any);
    }
  };

  // ---------------------------------- END OF INVITATIONS ----------------------------------

  const mutationActionsCallbacks = () => {
    return {
      onSuccess: (unique_event: UniqueEventI) => {
        setListTickets(unique_event.unique_events_tickets);
        toast.success(isEventPlan ? 'Evento guardado' : 'Capacitación guardada');
        reloadSession();
        if (isEdit) {
          refetchEvent();
        } else {
          navigate(`/eventos/${unique_event.id}/editar/contenido`);
        }
      },
      onError: (e: ErrorI) => {
        logAndNotify(`Error al crear / editar un evento - ${e}`);
        if (e.code && e.code === 'UE-001') {
          toast.error('Para cambiar la modalidad, el evento no tiene que tener reservas asociadas');
        } else if (e.code && e.code === 'BO-006') {
          // a ticket with bookings is trying to be deleted
          toast.error('El ticket no puede ser eliminado porque tiene reservas asociadas');
        } else {
          toast.error('Algo anda mal. Por favor, contactar a soporte.');
        }
      }
    };
  };

  const mutationCreate = useMutation<UniqueEventI, ErrorI>(
    (data) =>
      client('unique_events', 'POST', {
        isAuthRequired: true,
        isMultipartForm: true,
        data: data
      }),
    {
      ...mutationActionsCallbacks()
    }
  );

  const mutationEdit = useMutation<UniqueEventI, ErrorI>(
    (data) =>
      client(`unique_events/${id}`, 'PUT', {
        isAuthRequired: true,
        isMultipartForm: true,
        data
      }),
    {
      ...mutationActionsCallbacks()
    }
  );

  const confirmBeforeSubmit = () => {
    trackGenericEvent('Button Save Asset Clicked', { type: 'Unique Event' });

    setTimeout(() => {
      if (isEdit) {
        confirm({
          status: 'info',
          title: 'Confirmar cambios',
          content: `¿Guardar los cambios a ${isEventPlan ? 'este Evento' : 'esta Capacitación'}?`,
          confirmText: 'Si, guardar',
          onConfirm: () => fullSubmit()
        });
      } else {
        confirm({
          status: 'info',
          title: 'Nuevo Evento',
          content: `¿Querés publicar ${isEventPlan ? 'este Evento' : 'esta Capacitación'}?`,
          confirmText: 'Si, publicar',
          onConfirm: () => fullSubmit()
        });
      }
    }, 200);
  };

  const fullSubmit = () => {
    if (handleErrors()) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      handleSubmit(onSubmit, (e) => {
        if (e) {
          window.scrollTo({ top: 0, behavior: 'smooth' });
          const errors = Object.values(e).map((error) => error.message);
          toast.error(errors.join('\r\n'));
        } else {
          window.scrollTo({ top: 0, behavior: 'smooth' });
          toast.error('Error al guardar el servicio');
        }
      })();
    }
  };

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    const { eventName, meetLink, zoomLink, tickets, questions, eventDates } = data;

    // Filter empty dates
    const filteredEventDates = eventDates.filter(
      (eventDate: any) => eventDate.date !== '' && eventDate.startTime !== '' && eventDate.endTime !== ''
    );

    if (tickets.length === 0) return toast.error('Debes agregar al menos una entrada');

    const getSchedules = () => {
      const formatWithTimezone = (date: any, time: any) => {
        return dayjs.tz(`${dayjs(date).format('YYYY-MM-DD')} ${time}`, timezone).format();
      };

      if (isMultipleDates) {
        return JSON.stringify(
          filteredEventDates.map((item: EventDateI) => ({
            start_at: formatWithTimezone(item.date, item.startTime),
            end_at: formatWithTimezone(
              item.startTime > item.endTime ? dayjs(item.date).add(1, 'day') : item.date,
              item.endTime
            )
          }))
        );
      } else {
        return JSON.stringify([
          {
            start_at: formatWithTimezone(eventDates[0].date, eventDates[0].startTime),
            end_at: formatWithTimezone(
              eventDates[0].startTime > eventDates[0].endTime
                ? dayjs(eventDates[0].date).add(1, 'day')
                : eventDates[0].date,
              eventDates[0].endTime
            )
          }
        ]);
      }
    };

    const eventDuration = getMinutesBetweenDates(
      dayjs(eventDates[0].start_at).toDate(),
      dayjs(eventDates[0].end_at).toDate()
    );

    const isLocal = modality === ServiceModalityE.LOCAL || modality === ServiceModalityE.OPEN;

    const imageGalleryBase64 = [];
    for (let i = 0; i < imageGallery.length; i++) {
      const imageBlob = imageGallery[i].blob;

      if (imageBlob) {
        const imageBase64 = await blobToBase64(imageBlob);
        const image = {
          ...imageGallery[i],
          blob: imageBase64
        };
        imageGalleryBase64.push(image);
      } else if (imageGallery[i].url) {
        imageGalleryBase64.push({
          ...imageGallery[i]
        });
      }
    }

    const isBlob = coverImage?.includes('blob');
    const coverUrl = coverImage && isBlob ? await urlImageToFile(coverImage) : coverImage;

    if (isEdit) {
      const submitData: PostUniqueEventI = {
        name: eventName,
        description: description,
        is_booking_until_at_after: reservationTimeLimitOption?.value === 'after',
        booking_until_at_minutes: timeLimit?.value,
        policy_refund_mins: cancellationPolicies?.value || null,
        is_policy_available: toggleCancellationPolicy,
        is_transfer_fee_available: toggleUniqueEventFee,
        cover_url: coverUrl,
        image_gallery: imageGalleryBase64,
        modality: modality,
        password: password,
        is_limited_tickets: toggleMaxTicketsQuantity,
        max_tickets_quantity: maxTicketsQuantity,
        is_refund_available: toggleRefundAvailable,
        ...(isLocal && {
          address: selectedPlace!.address
        }),
        ...((modality === ServiceModalityE.VIRTUAL || modality === ServiceModalityE.OPEN) && {
          meet_type: meetType,
          meet_link: meetType === 'zoom' ? zoomLink : meetLink
        }),
        schedules: getSchedules(),
        tickets: JSON.stringify(tickets),
        questions: JSON.stringify(
          questions.map((item: QuestionRequestI, index: number) => ({ ...item, order: index }))
        ),
        duration: eventDuration,
        is_user_phone_required: toggleIsUserPhoneRequired,
        ...(faqs && { faqs: JSON.stringify(faqs) })
      };
      mutationEdit.mutate(submitData as any);
    } else {
      const submitData: PostUniqueEventI = {
        name: eventName,
        description: description,
        password: password,
        is_user_phone_required: true,
        is_booking_until_at_after: false,
        booking_until_at_minutes: null,
        policy_refund_mins: null,
        is_policy_available: false,
        is_transfer_fee_available: false,
        is_limited_tickets: false,
        is_refund_available: true,
        max_tickets_quantity: 1,
        cover_url: coverUrl,
        modality: modality,
        ...(isLocal && {
          address: selectedPlace!.address
        }),
        ...((modality === ServiceModalityE.VIRTUAL || modality === ServiceModalityE.OPEN) && {
          meet_type: meetType,
          meet_link: meetType === 'zoom' ? zoomLink : meetLink
        }),
        schedules: getSchedules(),
        tickets: JSON.stringify(tickets),
        duration: eventDuration
      };
      mutationCreate.mutate(submitData as any);
    }
  };

  const isMutationLoading = mutationCreate.isLoading || mutationEdit.isLoading;

  return (
    <UniqueEventContext.Provider
      value={{
        control,
        handleSubmit,
        errors,
        reset,
        ticketEditionMode,
        selectOptionsTimeLimit,
        selectOptionsCancellationPolicy,
        handleModality,
        handleMeetType,
        handleTimeLimit,
        handleDescription,
        handleLocationValue,
        handleSelectPlace,
        handlePolicy,
        handleCoverImage,
        handleImages,
        places,
        locationValue,
        selectedPlace,
        description,
        modality,
        meetType,
        timeLimit,
        cancellationPolicies,
        setCancellationPolicies,
        coverImage,
        setCoverImage,
        aboutErrors,
        handleErrors,
        isLoadingPlaces,
        setValue,
        watch,
        imageGallery,
        setImageGallery,
        isInternationalPaymentsEnabled,
        event,
        isLoadingEvent,
        isLoadingBookings,
        faqs,
        setFaqs,
        selectedTab,
        setSelectedTab,
        bookings,
        refetchBookings,
        confirmBeforeSubmit,
        toggleCancellationPolicy,
        setToggleCancellationPolicy,
        toggleUniqueEventFee,
        setToggleUniqueEventFee,
        isMutationLoading,
        selectReservationTimeLimitOptions,
        reservationTimeLimitOption,
        handlePassword,
        password,
        toggleMaxTicketsQuantity,
        setToggleMaxTicketsQuantity,
        maxTicketsQuantity,
        handleMaxTicketsQuantity,
        handleToggleCancellationPolicy,
        onKeyDownInvitation,
        handleCancelInvitation,
        handlePasteInvitation,
        toggleRefundAvailable,
        setToggleRefundAvailable,
        emailTags,
        inputInvitation,
        setInputInvitation,
        selectInvitationTicketsOptions,
        selectTicketInvitation,
        handleSubmitInvitation,
        mutationSendInvitationIsLoading,
        handleQuantityInvitation,
        quantityInvitation,
        getSlotsTicket,
        handleToggleMaxTicketsQuantity,
        isMultipleDates,
        handleIsMultipleDates,
        toggleIsUserPhoneRequired,
        setToggleIsUserPhoneRequired,
        isInfoMode,
        clients
      }}
    >
      {children}
    </UniqueEventContext.Provider>
  );
};

export const useUniqueEvent = (): UniqueEventContextType => useContext(UniqueEventContext);
