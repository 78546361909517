import { FunctionComponent, useCallback, useState } from 'react';
import { NavbarItem } from '..';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '@/components/DropdownMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row } from '@/components/Row';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import useScreenSize from '@/hooks/useScreenSize';

type MainMenuProps = {
  items: NavbarItem[];
};

export const MainMenu: FunctionComponent<MainMenuProps> = ({ items }) => {
  const [showDropdownMenu, setShowDropdownMenu] = useState<boolean>(false);
  const { isDesktop } = useScreenSize();

  const handleItemClick = (callback: () => void, onClick?: () => void) => {
    callback();
    onClick?.();
  };

  const closeDropdownMenu = useCallback(() => {
    if (showDropdownMenu) setShowDropdownMenu(false);
  }, [showDropdownMenu]);

  const renderMenuItem = (item: NavbarItem) => {
    const {
      label,
      onClick,
      icon,
      isSelected = false,
      disabled = false,
      isDropdown,
      dropdownContent,
      link
    } = item;

    const itemClasses = classNames(
      'flex items-center font-book text-sm relative transition-colors duration-200',
      {
        'text-blue': isSelected,
        'text-gray-700': !isSelected,
        'cursor-default': disabled,
        'hover:text-blue': !disabled
      }
    );

    if (!isDropdown) {
      return (
        <Link to={link}>
          <button className={itemClasses} onClick={onClick} disabled={disabled}>
            <div className="flex items-center">
              {icon && isDesktop && <FontAwesomeIcon icon={icon} fixedWidth className="mr-2" size="sm" />}
              <span>{label}</span>
            </div>
          </button>
        </Link>
      );
    } else {
      return (
        <DropdownMenu open={showDropdownMenu} onOpenChange={(open) => setShowDropdownMenu(open)}>
          <DropdownMenuTrigger onClick={() => setShowDropdownMenu(!showDropdownMenu)}>
            <button className={itemClasses} onClick={onClick} disabled={disabled}>
              <div className="flex items-center">
                {icon && isDesktop && <FontAwesomeIcon icon={icon} fixedWidth className="mr-2" size="sm" />}
                <span>{label}</span>
                <FontAwesomeIcon icon={faChevronDown} fixedWidth className="ml-1" size="xs" />
              </div>
            </button>
          </DropdownMenuTrigger>

          <DropdownMenuContent side="bottom" sideOffset={10}>
            {dropdownContent?.map(({ label, onClick, link, icon: dropdownIcon, isSelected }) => {
              return (
                <DropdownMenuItem
                  key={label}
                  className={classNames('cursor-pointer hover:bg-blue hover:text-white', {
                    'text-blue': isSelected
                  })}
                  asChild
                >
                  <Link to={link} key={label} onClick={() => handleItemClick(closeDropdownMenu, onClick)}>
                    <Row align="center" gap={8} className="cursor-pointer">
                      {dropdownIcon && <FontAwesomeIcon icon={dropdownIcon} fixedWidth />}
                      {label}
                    </Row>
                  </Link>
                </DropdownMenuItem>
              );
            })}
          </DropdownMenuContent>
        </DropdownMenu>
      );
    }
  };

  return (
    <div className="flex items-center gap-6">
      {items.map(
        (
          { label, onClick, icon, isSelected = false, disabled = false, dropdownContent, isDropdown, link },
          index
        ) => (
          <div key={index}>
            {renderMenuItem({
              label,
              onClick,
              icon,
              isSelected,
              disabled,
              dropdownContent,
              isDropdown,
              link
            })}
          </div>
        )
      )}
    </div>
  );
};
